
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('admission_form.fund_request') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('admission_form.fund_head')"
                            label-for="fund_sub_head_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fund_sub_head_id"
                                :options="fundSubHeadList"
                                id="fund_sub_head_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('admission_form.fund_request_list') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                    {{ $t('globalTrans.add_new') }}
                  </b-button>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ getStatus(data.item.status) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                    <b-button v-if="data.item.status === 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  </template>
                              </b-table>
                              <b-pagination
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
                </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :appGenInfoId="appGenInfoId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-1" size="xl" :title="$t('grantDistribution.fund_request_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" />
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { grantApplicantDetails, fundReqList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form,
    Details
  },
  data () {
    return {
      appGenInfoId: 0,
      search: {
        fund_sub_head_id: 0
      },
      item: ''
    }
  },
  computed: {
    fundSubHeadList () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.fundSubHeadList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('admission_form.fund_request') : this.$t('admission_form.fund_request') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.name'), class: 'text-left' },
        { label: this.$t('admission_form.fund_head'), class: 'text-left' },
        { label: this.$t('globalTrans.amount'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'fund_head_name_bn' },
          { key: 'requisition_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'fund_head_name' },
          { key: 'requisition_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    application () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails
    }
  },
  created () {
    this.getGrantApplicantDetails()
    // this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
      this.item = item
    },
    details (item) {
      this.item = item
    },
    getGrantApplicantDetails () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const params = Object.assign({ applicant_id: this.$store.state.Auth.authUser.id })
      RestApi.getData(incentiveGrantServiceBaseUrl, grantApplicantDetails, params).then(response => {
        if (response.success) {
          this.appGenInfoId = response.data.id
          this.loadData()
        }
        loadingState.listReload = false
        this.$store.dispatch('mutateCommonProperties', loadingState)
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, app_gen_info_id: this.appGenInfoId })
      await RestApi.getData(incentiveGrantServiceBaseUrl, fundReqList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        loadingState.listReload = false
        this.$store.dispatch('mutateCommonProperties', loadingState)
      })
    },
    getRelatinalData (data) {
      const fundSubHeadList = this.$store.state.ExternalUserIrrigation.incentiveGrant.fundSubHeadList

      const listData = data.map(item => {
        const fundObj = fundSubHeadList.find(fund => fund.value === item.fund_sub_head_id)

        const applicant = { name: item.generalinfo.name, name_bn: item.generalinfo.name_bn }
        const fundSubHeadData = { fund_head_name: fundObj !== undefined ? fundObj.text_en : '', fund_head_name_bn: fundObj !== undefined ? fundObj.text_bn : '' }

        return Object.assign({}, item, applicant, fundSubHeadData)
      })
      return listData
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroyItem(item)
        }
      })
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.approve')
      } else if (status === 3) {
        return this.$t('externalUserIrrigation.paid')
      }
    }
  }
}
</script>
