<template>
    <b-container fluid>
        <b-overlay :show="loading">
            <iq-card>
                <template v-slot:body>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col sm="6">
                                    <ValidationProvider name="Fund Sub Head" vid="fund_sub_head_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="fund_sub_head_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('admission_form.fund_head') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="form.fund_sub_head_id"
                                            :options="fundSubHeadList"
                                            id="fund_sub_head_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider name="Available Amount" vid="available_amount">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="available_amount"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('admission_form.available_amount') }}
                                        </template>
                                            <b-form-input
                                            id="available_amount"
                                            v-model="available_amount"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider name="Requisition Amount" vid="requisition_amount">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="requisition_amount"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('admission_form.requisition_amount')}} <span class="text-danger">*</span>
                                        </template>
                                            <b-form-input
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            id="requisition_amount"
                                            v-model="form.requisition_amount"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider name="Expense Breakdown">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="expense_breakdown"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('admission_form.expense_breakdown') }}
                                            </template>
                                            <b-form-file
                                                v-on:change="onFileExpenseBreakdownChange"
                                                v-model="form.expense_breakdown"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider name="Supervisor Recommendation">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="supervisor_recommendation"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('admission_form.supervisor_recommendation') }}
                                            </template>
                                            <b-form-file
                                                v-on:change="onFileSuperRecommendChange"
                                                v-model="form.supervisor_recommendation"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div class="text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                </template>
            </iq-card>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundReqUpdate, fundReqStore, getAvailableFund } from '../../../api/routes'

export default {
    props: ['id', 'appGenInfoId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            form: {
                app_gen_info_id: this.appGenInfoId,
                fund_sub_head_id: 0,
                requisition_amount: '',
                expense_breakdown: [],
                supervisor_recommendation: []
            },
            available_amount: 0,
            upload_expense_breakdown: '',
            upload_supervisor_recommendation: '',
            baseUrl: incentiveGrantServiceBaseUrl
        }
    },
    mounted () {
        core.index()
    },
    created () {
        if (this.id) {
            const tmp = this.getFundRequest()
            this.form = tmp
        }
    },
    computed: {
        fundSubHeadList () {
            return this.$store.state.ExternalUserIrrigation.incentiveGrant.fundSubHeadList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    watch: {
        'form.fund_sub_head_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.getAvailableAmount(newVal)
            }
        },
        'form.requisition_amount': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.checkAmount(newVal)
            }
        }
    },
    methods: {
        getFundRequest () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            this.getAvailableAmount(tmpData.fund_sub_head_id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        onFileExpenseBreakdownChange (e) {
            this.upload_expense_breakdown = e.target.files[0]
        },
        onFileSuperRecommendChange (e) {
            this.upload_supervisor_recommendation = e.target.files[0]
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            var formData = new FormData()
            Object.keys(this.form).map(key => {
                if (key === 'expense_breakdown') {
                    formData.append(key, this.upload_expense_breakdown)
                } else if (key === 'supervisor_recommendation') {
                    formData.append(key, this.upload_supervisor_recommendation)
                } else {
                    formData.append(key, this.form[key])
                }
            })
            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${fundReqUpdate}/${this.id}`, this.form, {
                    headers: { 'content-type': 'multipart/form-data' }
                })
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, fundReqStore, formData, {
                    headers: { 'content-type': 'multipart/form-data' }
                })
            }

            loadingState.listReload = true
            this.loading = false
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('mutateAgriMarketingProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        async getAvailableAmount (fundSubHead) {
            this.loading = true
            // const fund = this.$store.state.ExternalUserIrrigation.incentiveGrant.fundSubHeadList.find(fund => fund.value === fundSubHead)
            const params = Object.assign({ fund_sub_head_id: fundSubHead, app_gen_info_id: this.appGenInfoId })
            const response = await RestApi.getData(incentiveGrantServiceBaseUrl, getAvailableFund, params)
            this.loading = false
            if (response.success) {
                this.available_amount = response.availabe_amount
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: response.message
                })
                this.available_amount = 0
            }
        },
        checkAmount (requisitionAmount) {
            if (this.form.fund_sub_head_id === 2 || this.form.fund_sub_head_id === 3) {
                if (requisitionAmount > this.available_amount) {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('admission_form.requision_msg')
                    })
                    this.form.requisition_amount = 0
                }
            }
        }
    }
}
</script>
